import React, { useState } from "react";
import * as styles from '../../checkout-styles.module.scss'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { duration } from "moment";

function Arrow(props) {
	const disabled = props.disabled ? " arrow--disabled" : ""
	return (
	  <svg
		onClick={props.onClick}
		className={`arrow ${
		  props.left ? "arrow--left" : "arrow--right"
		} ${disabled}`}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
	  >
		{props.left && (
		  <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
		)}
		{!props.left && (
		  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
		)}
	  </svg>
	)
  }

const Suite = ({ room, diffDays, index }) => {
	const { t, i18n } = useTranslation()

	const getTitle = (title) => {
		const slugTitle = title.replace(/ /g, '-');
	
		const titles = {
			'Garden-River-Suite-ja': 'ガーデンリバースイート',
			'Garden-River-Suite-zh-hant': '花园河景套房',
			'Executive-River-Suite-ja': 'エグゼクティブ・リバー・スイート',
			'Executive-River-Suite-zh-hant': ' 行政河景套房',
			'Panorama-River-Suite-ja': 'パノラマリバースイート',
			'Panorama-River-Suite-zh-hant': '全景河景套房',
		};
	
		let titleStr = title;
	
		// console.log(titles, `${slugTitle}-${i18n.language.toLowerCase()}`)
	
		if(titles.hasOwnProperty(`${slugTitle}-${i18n.language.toLowerCase()}`)){
			titleStr = titles[`${slugTitle}-${i18n.language.toLowerCase()}`];
		}
	
		return titleStr;
	  }

	const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
	loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  }, [
	(slider) => {
	  let timeout
	  let mouseOver = false
	  function clearNextTimeout() {
		clearTimeout(timeout)
	  }
	  function nextTimeout() {
		clearTimeout(timeout)
		if (mouseOver) return
		let dur = 3000;
		if(index){
			dur = 1000 + (index * 1000)
		}
		timeout = setTimeout(() => {
		  slider.next()
		}, dur)
	  }
	  slider.on("created", () => {
		slider.container.addEventListener("mouseover", () => {
		  mouseOver = true
		  clearNextTimeout()
		})
		slider.container.addEventListener("mouseout", () => {
		  mouseOver = false
		  nextTimeout()
		})
		nextTimeout()
	  })
	  slider.on("dragStarted", clearNextTimeout)
	  slider.on("animationEnded", nextTimeout)
	  slider.on("updated", nextTimeout)
	},
  ])

	return (
		<div className={styles.checkout__aside__room_info}>
			<div className={styles.checkout__aside__room_info__a}>
				<p>{getTitle(room.roomTypeName)} <span><em>x</em>{room.quantity}</span></p>
				<p>{t('checkout.aside.price2', { price: room.roomRate / _.toNumber(diffDays) })}</p>
			</div>
			<div className={styles.checkout__aside__room_info__b}>
				{/* <img src={room?.roomPhotos[0]?.image} alt={getTitle(room.roomTypeName)} /> */}
				<div className="navigation-wrapper checkout">
					<div ref={sliderRef} className="keen-slider" style={{ height: '100%' }}>
						{room?.roomPhotos.map((img, key) => (
							<div className="keen-slider__slide" key={key}>
								<img src={img.image} alt={`Image ${key}`} />
							</div>
						))}
					</div>
					{/* {loaded && instanceRef.current && (<>
						<Arrow
						left
						onClick={(e) =>
							e.stopPropagation() || instanceRef.current?.prev()
						}
						disabled={currentSlide === 0}
						/>

						<Arrow
						onClick={(e) =>
							e.stopPropagation() || instanceRef.current?.next()
						}
						disabled={
							currentSlide ===
							instanceRef.current.track.details.slides.length - 1
						}
						/>
					</>)} */}
				</div>
			</div>
		</div>
	)
}

export default Suite;