/* eslint-disable no-unused-vars */
import axios from 'axios'
import _ from 'lodash'
import React, { useState, useRef, useEffect } from 'react'
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik'
// import { Trans } from 'react-i18next'
import { getCountryCallingCode } from 'react-phone-number-input'
import Select from 'react-select'
import customStyles from './customStylesDropdown'
import SignupSchema from './validation-schema'
import Lock from '../../../../assets/icons/reflections_icon_lock.svg'
import stripeBadge from '../../../../assets/images/stripe-badge-transparent.png'
import paymentsIcon from '../../../../assets/images/payments.png'
import countriesOptions from '../../../../helpers/country-list/countries'
import phoneOptions from '../../../../helpers/phone-list/phones'
import * as styles from './form-contact-styles.module.scss'
import visa from '../../../../assets/images/Visa-dark.svg'
import amex from '../../../../assets/images/AmericanExpress-dark.svg'
import mastercard from '../../../../assets/images/MasterCard-dark.svg'
import union from '../../../../assets/images/UnionPay-dark.svg'

const FormContact = ({
  t,
  onHandledPaymentClick,
  isPaymentLoading,
}) => {
  const formRef = useRef()
  const stripe = useStripe()
  const elements = useElements()
  const [isCardComplete, setIsCardComplete] = useState(false);

  const handledOpenBooking = (e) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return
    }
    if (_.isEmpty(formRef.current)) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }    

    onHandledPaymentClick(e, formRef.current.values)
  }
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const onHandledOpenInfo = () => {
    setIsInfoOpen(!isInfoOpen)
  }
  useEffect(() => {
  }, [formRef])

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    if (res.data) {
      // formRef.current.initialValues.prefix = getCountryCallingCode(res.data.country_code)
	  console.log(res.data.country_code)
      formRef.current.setFieldValue('prefix', `${getCountryCallingCode(res.data.country_code)}`)
	  formRef.current.setFieldValue('phone', `${getCountryCallingCode(res.data.country_code).includes('+') ? getCountryCallingCode(res.data.country_code) : `+${getCountryCallingCode(res.data.country_code)}`}`);
    }
  }

  useEffect(() => {
	formRef.current.setFieldValue('prefix', ``)
    getData()
  }, [])

  const SelectField = ({
    options,
    field,
    form,
	onChange,
  }) => (
    <Select
      options={options}
      name={field.name}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={(option) => {
		form.setFieldValue(field.name, option.value)
		onChange(option.value)
	  }}
      onBlur={field.onBlur}
      styles={customStyles}
    />
  )
  console.log('formRef.current', formRef.current)

  const handleCountryCodeChange = (val) => {
	// console.log(val);
	formRef.current.setFieldValue('phone', `${val.includes('+') ? val : `+${val}`}`);
  }

  const onPhoneChange = (e) => {
	// console.log(e.target.value)
    if (/^[0-9+]*$/.test(e.target.value)) {
      formRef.current.setFieldValue('phone', e.target.value)
    }
  }

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete);
  };

  let arrivalHours = []

  for (let hour = 0; hour < 24; hour++) {
	for (let minute = 0; minute < 60; minute += 30) {
	  const hourStr = hour.toString().padStart(2, "0");
	  const minuteStr = minute.toString().padStart(2, "0");
	  arrivalHours.push({ label: `${hourStr}:${minuteStr}`, value: `${hourStr}:${minuteStr}` });
	}
  }

  return (
    <div className={styles.formContact__container}>
      <div className={styles.formContact__head}>
        <h2>{t('checkout.form.title')}</h2>
        <p>{t('checkout.form.mandatory')}</p>
        {/* <p>{t('checkout.form.requieredMessage')}</p> */}

      </div>
      <div className={styles.formContact__form}>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={{
            firstName: '',
            lastName: '',
            phone: '',
            prefix: '',
            email: '',
            cardName: '',
            country: '',
            code: '',
			arrivialtime: '',
            agreement: false,
          }}
          validationSchema={SignupSchema}
        >
          {({ isSubmitting, isValid, errors }) => (
              console.log('isValid', isValid),
              console.log('errors', errors),
            <Form>
              <div className={styles.formContact__form__row}>
                <div className={`${styles.formContact__form__group} ${styles.form__group}`}>
                  <Field type="text" name="firstName" className={styles.form__field} placeholder={t('checkout.form.firstName')} />
                  <label className={styles.form__label} htmlFor="fistName">{t('checkout.form.firstName')}</label>
                  <ErrorMessage className={styles.formContact__form__error} name="firstName" component="div" />
                </div>
                <div className={`${styles.formContact__form__group} ${styles.form__group}`}>
                  <Field placeholder={t('checkout.form.lastName')} className={styles.form__field} type="text" name="lastName" />
                  <label className={styles.form__label} htmlFor="lastName">{t('checkout.form.lastName')}</label>
                  <ErrorMessage className={styles.formContact__form__error} name="lastName" component="div" />
                </div>
              </div>
              <div className={styles.formContact__form__row}>
                <div className={`${styles.formContact__form__group} ${styles.form__group}`}>
                  <Field placeholder={t('checkout.form.email')} className={styles.form__field} type="email" name="email" />
                  <label className={styles.form__label} htmlFor="email">{t('checkout.form.email')}</label>
                  <ErrorMessage className={styles.formContact__form__error} name="email" component="div" />
                </div>
                <div className={`${styles.formContact__form__group} ${styles.form__group} ${styles.formContact__form__group__double}`}>
                  <div>
                    <Field
                      placeholder={t('checkout.form.phone')}
                      className={styles.form__field}
					  component={SelectField}
					  options={phoneOptions}
                      name="prefix"
					  onChange={handleCountryCodeChange}
                    />
                    <label className={styles.form__label} htmlFor="prefix">{t('checkout.form.prefix')}</label>
                    <ErrorMessage className={styles.formContact__form__error} name="prefix" component="div" />
                  </div>
                  <div>
                    <Field placeholder={t('checkout.form.phone')} className={styles.form__field} type="tel" name="phone" onChange={onPhoneChange} />
                    <label className={styles.form__label} htmlFor="phone">{t('checkout.form.phone')}</label>
                    <ErrorMessage className={styles.formContact__form__error} name="phone" component="div" />
                  </div>
                </div>
              </div>
              <div className={styles.formContact__form__row}>
                <div className={`${styles.formContact__form__group} ${styles.form__group}`}>
                  <label className={styles.form__label} htmlFor="country">{t('checkout.form.country')}</label>
                  <Field
                    name={'country'}
                    component={SelectField}
                    options={countriesOptions}
                    type="country"
                  />
                  {/* <Select
                    onChange={(option) => formRef.current.setFieldValue('country', option.value)}

                    defaultValue={countriesOptions[0]}
                    options={countriesOptions} /> */}
                  <ErrorMessage className={styles.formContact__form__error} name="country" component="div" />
                </div>
				<div className={`${styles.formContact__form__group} ${styles.form__group} ${styles.formContact__form__group__double}`}>
					<div>
						<Field placeholder={t('checkout.form.code')} className={styles.form__field} type="code" name="code" style={{ textTransform: 'uppercase' }} />
						<label className={styles.form__label} htmlFor="code">{t('checkout.form.code')}</label>
						<ErrorMessage className={styles.formContact__form__error} name="code" component="div" />
					</div>
					<div>
						<Field
							name={'arrivaltime'}
							component={SelectField}
							options={arrivalHours}
							type="arrivaltime"
						/>
						<label className={styles.form__label} htmlFor="arrival">{t('checkout.form.arrival')}</label>
                    	<ErrorMessage className={styles.formContact__form__error} name="arrival" component="div" />
					</div>
				</div>
              </div>

			  <div className={styles.formContact__head2}>
					<h2>{t('checkout.form.paymentTitle')}</h2>
					<p>{t('checkout.form.paymentMandatory')}</p>
					{/* <p>{t('checkout.form.requieredMessage')}</p> */}

				</div>
				<div className={styles.formPayment__form__row}>
                <div className={`${styles.formPayment__form__group} ${styles.form__group}`}>
                  <div>
                    <Field placeholder={t('checkout.payments.cardName')} className={styles.form__field} type="text" name="cardName" />
                    <label className={styles.form__label} htmlFor="prefix">{t('checkout.payments.cardName')}</label>
                    <ErrorMessage className={styles.formPayment__form__error} name="cardName" component="div" />
                  </div>
                </div>
              </div>
              <div className={styles.formPayment__form__row}>


                <CardElement
                  onChange={handleCardChange}
                  className={styles.card}
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        backgroundColor: 'transparent',
                      },
                    },
                  }}
                />
              </div>
              <div className={styles.formPayment__box__checkbox}>
                <label className={styles.formPayment__checkbox}>
                  {t('checkout.payments.agree')}
                  <Field type="checkbox" name="agreement" />
                  <span className={styles.formPayment__checkbox__span}></span>
                </label>
              </div>
              <div className={styles.formPayment__button__box}>
                <div className={styles.formPayment__button__secure}>
                  <img src={stripeBadge} alt={'secure payment'} />
				  {/* <Lock width="22"/>
				<div>
					Secure online payment <span onClick={onHandledOpenInfo}> Learn More</span>.
				</div> */}
				  <div className={styles.formPayment__button__secure_text}>
					<div className={styles.formPayment__button__secure_text_a}>
						<Lock width="22"/>
						<div>
							Secure online payment <span onClick={onHandledOpenInfo}> Learn More</span>.
						</div>
					</div>
					<div className={styles.formPayment__button__secure_text_b}>
						<img src={visa} alt="Visa" />
						<img src={mastercard} alt="Mastercard" />
						<img src={union} alt="UnionPay" />
						<img src={amex} alt="American Express" />
					</div>
				  </div>
                </div>
                <button
                  disabled={isSubmitting || !isValid || !isCardComplete || errors.cardName || errors.email || errors.phone || errors.firstName || errors.lastName || errors.prefix || errors.country || errors.code || errors.arrivaltime}
                  onClick={handledOpenBooking}
                  className={styles.formPayment__button}>
                  <span>{isPaymentLoading ? t('checkout.payments.loading') : t('checkout.payments.complete')}</span>
                </button>
              </div>

              <div className={styles.formPayment__message}>
                {/* todo */}
                {/* {successEmail && <span className={styles.formPayment__message__success}>{t('contact.form.success')}</span>}
              {errorEmail && <span className={styles.formPayment__message__error}>{t('contact.form.error')}</span>} */}
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>
  )
}

export default FormContact
