// extracted by mini-css-extract-plugin
export var active = "checkout-styles-module--active--496a1";
export var arrow = "checkout-styles-module--arrow--b13e4";
export var arrowDisabled = "checkout-styles-module--arrow--disabled--ed385";
export var arrowLeft = "checkout-styles-module--arrow--left--ec962";
export var arrowRight = "checkout-styles-module--arrow--right--baad7";
export var big = "checkout-styles-module--big--06984";
export var blink = "checkout-styles-module--blink--8c6f0";
export var bookingBtn = "checkout-styles-module--booking-btn--daa3a";
export var bookingPageHeader = "checkout-styles-module--booking-page-header--beaf2";
export var booking__step__number = "checkout-styles-module--booking__step__number--61fad";
export var checkout = "checkout-styles-module--checkout--377cd";
export var checkout__aside = "checkout-styles-module--checkout__aside--3599b";
export var checkout__aside2 = "checkout-styles-module--checkout__aside2--ae172";
export var checkout__aside2__book = "checkout-styles-module--checkout__aside2__book--6d080";
export var checkout__aside2__code = "checkout-styles-module--checkout__aside2__code--b56da";
export var checkout__aside2__notEnoughtRooms = "checkout-styles-module--checkout__aside2__notEnoughtRooms--808d0";
export var checkout__aside2__price = "checkout-styles-module--checkout__aside2__price--5f722";
export var checkout__aside2__pricePromoCode = "checkout-styles-module--checkout__aside2__pricePromoCode--816bf";
export var checkout__aside2__prices = "checkout-styles-module--checkout__aside2__prices--8ae44";
export var checkout__aside2__room = "checkout-styles-module--checkout__aside2__room--12f18";
export var checkout__aside__arrival = "checkout-styles-module--checkout__aside__arrival--90431";
export var checkout__aside__content = "checkout-styles-module--checkout__aside__content--5914c";
export var checkout__aside__grandTotal = "checkout-styles-module--checkout__aside__grandTotal--51481";
export var checkout__aside__grandTotaltext = "checkout-styles-module--checkout__aside__grandTotaltext--ec9af";
export var checkout__aside__grandTotalwrap = "checkout-styles-module--checkout__aside__grandTotalwrap--661ab";
export var checkout__aside__rateDetailsBtn = "checkout-styles-module--checkout__aside__rateDetailsBtn--45274";
export var checkout__aside__rateDetailsContent = "checkout-styles-module--checkout__aside__rateDetailsContent--79558";
export var checkout__aside__room_info = "checkout-styles-module--checkout__aside__room_info--f01dc";
export var checkout__aside__room_info__a = "checkout-styles-module--checkout__aside__room_info__a--4805c";
export var checkout__aside__room_info__b = "checkout-styles-module--checkout__aside__room_info__b--33db5";
export var checkout__aside__selected = "checkout-styles-module--checkout__aside__selected--de10c";
export var checkout__aside__selected__subtitle = "checkout-styles-module--checkout__aside__selected__subtitle--d037d";
export var checkout__aside__selected__subtitle2 = "checkout-styles-module--checkout__aside__selected__subtitle2--862eb";
export var checkout__aside__total = "checkout-styles-module--checkout__aside__total--8492f";
export var checkout__back = "checkout-styles-module--checkout__back--973be";
export var checkout__box = "checkout-styles-module--checkout__box--1e34c";
export var checkout__container = "checkout-styles-module--checkout__container--8affb";
export var checkout__content = "checkout-styles-module--checkout__content--d49ba";
export var checkout__form = "checkout-styles-module--checkout__form--6c8ea";
export var checkout__map = "checkout-styles-module--checkout__map--09ebe";
export var checkout__sidebar = "checkout-styles-module--checkout__sidebar--a939d";
export var checkout__step = "checkout-styles-module--checkout__step--83ea3";
export var checkout__step__active = "checkout-styles-module--checkout__step__active--f0d76";
export var checkout__steps = "checkout-styles-module--checkout__steps--fa065";
export var checkout__title = "checkout-styles-module--checkout__title--d3277";
export var commentsSlider = "checkout-styles-module--commentsSlider--cc777";
export var dot = "checkout-styles-module--dot--85af6";
export var dots = "checkout-styles-module--dots--2b8fe";
export var equallyBound = "checkout-styles-module--equallyBound--0f85f";
export var fader = "checkout-styles-module--fader--55585";
export var fader__slide = "checkout-styles-module--fader__slide--fde9c";
export var featuredSlider = "checkout-styles-module--featuredSlider--4afff";
export var globalWrapper = "checkout-styles-module--global-wrapper--be6d7";
export var headerIcons = "checkout-styles-module--header-icons--bf793";
export var homepage = "checkout-styles-module--homepage--d6a09";
export var homepageReviews = "checkout-styles-module--homepage-reviews--f0d66";
export var isOpenMenu = "checkout-styles-module--is-open-menu--91c6d";
export var keenSlider = "checkout-styles-module--keen-slider--28512";
export var lineMove = "checkout-styles-module--lineMove--e2348";
export var logo = "checkout-styles-module--logo--b1fdf";
export var navigationWrapper = "checkout-styles-module--navigation-wrapper--aac87";
export var scrollNav = "checkout-styles-module--scrollNav--115b0";
export var scrollNavWrap = "checkout-styles-module--scrollNavWrap--4c779";
export var slickSlide = "checkout-styles-module--slick-slide--33be3";
export var startTxtArea = "checkout-styles-module--startTxtArea--52663";