import React, { useEffect, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import * as styles from '../../checkout-styles.module.scss'
import Suite from './suite'

const AsideCheckout = ({
  t,
  enddate,
  startdate,
  bookingRooms,
  paramsInStore,
}) => {
  const [params, setParams] = useState([])
  useEffect(() => {
    const pa = JSON.parse(sessionStorage.getItem('paramsBooking'))
    setParams(pa)
  }, [])

  console.log("CHECKOUTPARAMS", params)
//   console.log("CHECKOUTROOMS", params?.rooms)
  const { i18n } = useTranslation()

  const getTitle = (title) => {
    const slugTitle = title.replace(/ /g, '-');

    const titles = {
        'Garden-River-Suite-ja': 'ガーデンリバースイート',
        'Garden-River-Suite-zh-hant': '花园河景套房',
        'Executive-River-Suite-ja': 'エグゼクティブ・リバー・スイート',
        'Executive-River-Suite-zh-hant': ' 行政河景套房',
        'Panorama-River-Suite-ja': 'パノラマリバースイート',
        'Panorama-River-Suite-zh-hant': '全景河景套房',
    };

    let titleStr = title;

    console.log(titles, `${slugTitle}-${i18n.language.toLowerCase()}`)

    if(titles.hasOwnProperty(`${slugTitle}-${i18n.language.toLowerCase()}`)){
        titleStr = titles[`${slugTitle}-${i18n.language.toLowerCase()}`];
    }

    return titleStr;
  }

  const getRateDetails = () => {
	let details = `<strong>Additional Information</strong>
					<p>Commissionable Rate</p>
					<strong>Holding Your Reservation</strong>
					<p>We will need a credit card number to reserve your room.</p>
					<strong>Cancelling Your Reservation</strong>
					<p>You may cancel your reservation for no charge 3 day[s] before arrival. Please note that we will assess a fee of 30% of your Grand Total, if you must cancel after this deadline.</p>
					<strong>Modifying Your Reservation</strong>
					<p>Any change in the length or dates of a reservation may result in a rate change.</p>`;

	
	if(i18n.language.toLowerCase() === 'ja'){
		details = `<strong>追加情報</strong>
		<p>手数料率</p>
		<strong>予約の保留</strong>
		<p>部屋を予約するにはクレジット カード番号が必要です。</p>
		<strong>予約のキャンセル</strong>
		<p>到着の 3 日前までは無料で予約をキャンセルできます。 この期限を過ぎてキャンセルする必要がある場合、総計の 30% の手数料が課されることにご注意ください。</p>
		<strong>予約の変更</strong>
		<p>予約の長さや日付を変更すると、料金が変更される場合があります。</p>`;
	}else if(i18n.language.toLowerCase() === 'zh-hant'){
		details = `<strong>其他資訊</strong>
		<p>佣金率</p>
		<strong>保留您的預訂</strong>
		<p>我們需要信用卡號碼來預訂您的房間。</p>
		<strong>取消您的預訂</strong>
		<p>您可以在抵達前 3 天免費取消預訂。 請注意，如果您必須在此截止日期之後取消，我們將收取您總計 30% 的費用。</p>
		<strong>修改您的預訂</strong>
		<p>預訂時長或日期的任何變更都可能導致價格變動。</p>`;
	}

	return details;
  }

  const [isOpenRates, setIsOpenRates] = useState(false)

  let totalAdults = _.reduce(params.adults, (t, o) => t + o.quantity, 0)
  let totalChildren = _.reduce(params.children, (t, o) => t + o.quantity, 0)
//   console.log("Total Persons", totalAdults, totalChildren)
  const promoCode = 0
  const totalRoom = _.reduce(params.rooms, (acc, room) => acc + (room.roomRate * room.quantity), 0)
//   const amount = ((_.reduce(paramsInStore.rooms, (acc, room) => acc + room.roomRate, 0) / 100) * 30)
const diffDays = moment.duration(moment(enddate).diff(moment(startdate))).asDays()
const localTax = (((totalAdults + totalChildren) * diffDays) * 1000);
const amount = (( (totalRoom / 100) + (((totalRoom / 100) * 0.1) + (localTax / 100)) + ((totalRoom / 100) * 0.1) ) * 30)
  const [totalPrice, setTotalPrice] = useState(0)
  return (
    <aside className={styles.checkout__aside}>
      <div className={styles.checkout__aside__content}>
        <h2>{t('checkout.aside.title')}</h2>
        <div className={styles.checkout__aside__selected}>
          <div>
            <span className={styles.checkout__aside__selected__subtitle}>{t('checkout.aside.roomSelections')}</span>
            {
              _.map(params.rooms, (room, index) => (
				<React.Fragment key={room.roomTypeName}>
					<Suite room={room} diffDays={diffDays} index={index + 1} />
				</React.Fragment>
              ))

            }

          </div>
		  <div className={styles.checkout__aside__arrival}>
			<div>
				<span className={styles.checkout__aside__selected__subtitle2}>{t('checkout.aside.arrivalDep')}</span>
				<p>{moment(startdate).format('DD/MM/YYYY')} - {moment(enddate).format('DD/MM/YYYY')}</p>
			</div>
			<div>
				<span className={styles.checkout__aside__selected__subtitle2}>{t('checkout.aside.nights')}</span>
				<p>{diffDays}</p>
			</div>
		  </div>
        </div>
        <div className={styles.checkout__aside__total}>
          <table>
            <tbody>
              <tr>
                <td>{t('checkout.aside.roomTotals')}</td>
                <td>{t('checkout.aside.price', { price: totalRoom })}</td>
              </tr>{
				promoCode > 0 && <tr>
                <td>{t('checkout.aside.promote')}</td>
                <td>{promoCode === 0 ? 'N/A' : promoCode}</td>
              </tr>
			  }
			  <tr>
                <td>{t('checkout.aside.taxes')}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{t('checkout.aside.price', { price: ((totalRoom * 0.10) + localTax) })}</td>
              </tr>
			  <tr>
                <td>{t('checkout.aside.serviceCharge')}</td>
                <td>{t('checkout.aside.price', { price: (totalRoom * 0.10) })}</td>
              </tr>

			  <tr>
				<td colSpan={2} style={{ height: '1px', width: '100%', padding: '16px 0' }}>
					<div style={{ backgroundColor: '#E0D9D1', height: '1px', width: '100%' }}></div>
				</td>
			  </tr>
              
              <tr className={styles.checkout__aside__grandTotalwrap}>
                <td className={styles.checkout__aside__grandTotaltext}>{t('checkout.aside.total')}</td>
                <td className={styles.checkout__aside__grandTotal}>{t('checkout.aside.price', { price: (totalRoom + ((totalRoom * 0.10) + localTax) + (totalRoom * 0.10)) - (promoCode) })}</td>
              </tr>
			  <tr>
                <td>
					<div>{t('checkout.aside.deposit')}</div>
					<div style={{ color: '#D87021', fontSize: '10px', lineHeight: '1', marginTop: '-2px' }}>{t('checkout.aside.duenow')}</div>
				</td>
                <td style={{ whiteSpace: 'nowrap' }}>{t('checkout.aside.priceDeposit', { price: amount })}</td>
              </tr>
            </tbody>
          </table>
		  <p className={styles.checkout__aside__rateDetailsBtn} onClick={() => {
				setIsOpenRates(!isOpenRates)
			}}>
				{isOpenRates ? t('booking.buttons.hideRates') : t('booking.buttons.rates')}
			</p>
		  {
				isOpenRates && (
					<div className={styles.checkout__aside__rateDetailsContent}>
						<h6 style={{ display: 'none' }}>{t('booking.buttons.rates')}</h6>
						<div
							dangerouslySetInnerHTML={{ __html: getRateDetails() }} />
					</div>
				)
			}
        </div>
      </div>
    </aside>

  )
}

export default AsideCheckout
