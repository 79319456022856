/* eslint-disable no-unused-vars */
const customStyles = {
	indicatorsContainer: (provided) => ({
		...provided,
		backgroundColor: 'transparent',
		border: 'none',
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: 0,
		color: '#000 !important',
		width: '16px',
		'svg': {
		  fill: 'currentColor'
		}
	}),
	control: (provided) => ({
		...provided,
		backgroundColor: 'transparent',
		borderRadius: '0',
		border: '0 !important',
		borderBottom: '1px solid #000 !important',
		boxShadow: 'none !important',
		fontFamily: 'Sofia Pro Regular',
		// none of react-select's styles are passed to <Control />
		width: '100%',
		// '@media only screen and (max-width: 764px)': {
		// width: 128,
		// },
	}),
	valueContainer: (provided) => ({
		...provided,
		padding: 0
	}),
	menuList: (provided) => ({
		...provided,
		fontFamily: 'Sofia Pro Regular',
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 2,
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.data?.value === state.selectProps?.value?.value
		? 'rgba(216, 112, 33, 1)'
		: 'transparent',
		'&:hover': {
		backgroundColor: 'rgba(216, 112, 33, 0.4)',
		},
		'&:active': {
		backgroundColor: 'rgba(216, 112, 33, 0.4)',
		},
	}),
	singleValue: (provided) => ({
		...provided,
		lineHeight: '27px',
	})
}

export default customStyles
