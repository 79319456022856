
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  cardName: Yup.string().required('Card name is required'),
  country: Yup.string().required('Country is required'),
  code: Yup.string().required('Code is required'),
  arrivaltime: Yup.string().required('Arrival time is required'),
  agreement: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),  
});
export default SignupSchema;
