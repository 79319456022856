// extracted by mini-css-extract-plugin
export var active = "form-contact-styles-module--active--c19e4";
export var arrow = "form-contact-styles-module--arrow--77e2e";
export var arrowDisabled = "form-contact-styles-module--arrow--disabled--dbfb6";
export var arrowLeft = "form-contact-styles-module--arrow--left--fc414";
export var arrowRight = "form-contact-styles-module--arrow--right--1d9a7";
export var blink = "form-contact-styles-module--blink--2342d";
export var bookingBtn = "form-contact-styles-module--booking-btn--f79b8";
export var bookingPageHeader = "form-contact-styles-module--booking-page-header--86fe9";
export var card = "form-contact-styles-module--card--479c2";
export var checkout = "form-contact-styles-module--checkout--dd86c";
export var commentsSlider = "form-contact-styles-module--commentsSlider--36cb8";
export var dot = "form-contact-styles-module--dot--1d726";
export var dots = "form-contact-styles-module--dots--9ca5f";
export var equallyBound = "form-contact-styles-module--equallyBound--aee9c";
export var fader = "form-contact-styles-module--fader--0d9c3";
export var fader__slide = "form-contact-styles-module--fader__slide--be750";
export var featuredSlider = "form-contact-styles-module--featuredSlider--8d61f";
export var formContact__button = "form-contact-styles-module--formContact__button--8df44";
export var formContact__container = "form-contact-styles-module--formContact__container--022b6";
export var formContact__form = "form-contact-styles-module--formContact__form--acb69";
export var formContact__form__container = "form-contact-styles-module--formContact__form__container--66ddd";
export var formContact__form__error = "form-contact-styles-module--formContact__form__error--330ea";
export var formContact__form__group = "form-contact-styles-module--formContact__form__group--b9e36";
export var formContact__form__group__double = "form-contact-styles-module--formContact__form__group__double--1f4a7";
export var formContact__form__row = "form-contact-styles-module--formContact__form__row--5c114";
export var formContact__head = "form-contact-styles-module--formContact__head--eadfc";
export var formContact__head2 = "form-contact-styles-module--formContact__head2--0c9b7";
export var formPayment__box__checkbox = "form-contact-styles-module--formPayment__box__checkbox--6cf92";
export var formPayment__button = "form-contact-styles-module--formPayment__button--56575";
export var formPayment__button__box = "form-contact-styles-module--formPayment__button__box--47e58";
export var formPayment__button__secure = "form-contact-styles-module--formPayment__button__secure--7d01c";
export var formPayment__button__secure_text = "form-contact-styles-module--formPayment__button__secure_text--6162a";
export var formPayment__button__secure_text_a = "form-contact-styles-module--formPayment__button__secure_text_a--af29d";
export var formPayment__button__secure_text_b = "form-contact-styles-module--formPayment__button__secure_text_b--cc7db";
export var formPayment__checkbox = "form-contact-styles-module--formPayment__checkbox--cb9bd";
export var formPayment__checkbox__span = "form-contact-styles-module--formPayment__checkbox__span--d74d4";
export var formPayment__container = "form-contact-styles-module--formPayment__container--12a93";
export var formPayment__creditCards = "form-contact-styles-module--formPayment__creditCards--46e48";
export var formPayment__form = "form-contact-styles-module--formPayment__form--40cfc";
export var formPayment__form__capitalize = "form-contact-styles-module--formPayment__form__capitalize--1af4b";
export var formPayment__form__container = "form-contact-styles-module--formPayment__form__container--44571";
export var formPayment__form__error = "form-contact-styles-module--formPayment__form__error--6d211";
export var formPayment__form__group = "form-contact-styles-module--formPayment__form__group--6b73b";
export var formPayment__form__group__double = "form-contact-styles-module--formPayment__form__group__double--bb5db";
export var formPayment__form__row = "form-contact-styles-module--formPayment__form__row--ae0bd";
export var formPayment__head = "form-contact-styles-module--formPayment__head--ddbc1";
export var form__field = "form-contact-styles-module--form__field--94f0f";
export var form__group = "form-contact-styles-module--form__group--8531c";
export var form__label = "form-contact-styles-module--form__label--8fd48";
export var globalWrapper = "form-contact-styles-module--global-wrapper--588ad";
export var headerIcons = "form-contact-styles-module--header-icons--ba24d";
export var homepage = "form-contact-styles-module--homepage--de241";
export var homepageReviews = "form-contact-styles-module--homepage-reviews--7e630";
export var isOpenMenu = "form-contact-styles-module--is-open-menu--a4013";
export var is__active = "form-contact-styles-module--is__active--7cc1a";
export var keenSlider = "form-contact-styles-module--keen-slider--9ccf7";
export var lineMove = "form-contact-styles-module--lineMove--21816";
export var logo = "form-contact-styles-module--logo--f40d1";
export var navigationWrapper = "form-contact-styles-module--navigation-wrapper--6bd7d";
export var scrollNav = "form-contact-styles-module--scrollNav--7e206";
export var scrollNavWrap = "form-contact-styles-module--scrollNavWrap--150f2";
export var slickSlide = "form-contact-styles-module--slick-slide--cc8d9";
export var startTxtArea = "form-contact-styles-module--startTxtArea--e0aad";