import _ from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { navigate } from 'gatsby'
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { makeRequest } from '../../helpers'
import { useRooms } from '../../hooks/use-rooms'
import { useBooking } from '../../hooks/use-booking'
import getAllUrlParams from '../../helpers/get-query-params'
import CheckoutView from './checkout-view'
import { useTranslation } from 'react-i18next'
import toast from '../../helpers/toast'
import moment from 'moment'

const CheckoutController = ({ location }) => {
  const [paramsInStore, setParamsInStore] = useState([])
  const [isPaymentLoading, setPaymentLoading] = useState(false)
  const [loadingProperty, setLoadingProperty] = useState(true)
  const [bookingRooms, setBookingRooms] = useState([])
  const [hotelDetails, setHotelDetails] = useState([])
  const [hasError, setHasError] = useState(false)
  const [adults, setAdults] = useState(0)
  const [children, setChildren] = useState(0)
  const stripe = useStripe()
  const elements = useElements()
  const {
    roomsAvailables,
    setTotalRooms,
  } = useBooking()
  const { href } = location
  const params = getAllUrlParams(href)

  const { t, i18n } = useTranslation();

  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if(activeLanguage) {
        switch(activeLanguage){
            case 'ja':
                link = '/ja/';
                break;
            case 'zh-hant':
                link = '/zh-hant/';
                break;
            default: link = '/';
        }
    }
    return link;
  }

  const getHotelDetails = useCallback(async () => {
    setLoadingProperty(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      custom: true,
      endPoint: 'getPropertyInfo',
      params: {
        // lang: i18n.language.toLowerCase()
      },
    }).then((resp) => {
      setHotelDetails(resp.data)
      setLoadingProperty(false)
    })
  }, [])

  const onHandledPayment = (token, values) => {
    // setPaymentLoading(true)
    const headers = {
      'Content-Type': 'application/form-data',
    }
	let totalAdults = _.reduce(paramsInStore.adults, (t, o) => t + o.quantity, 0)
  	let totalChildren = _.reduce(paramsInStore.children, (t, o) => t + o.quantity, 0)
	const diffDays = moment.duration(moment(paramsInStore.endDate).diff(moment(paramsInStore.startDate))).asDays()
    // const amount = ((_.reduce(paramsInStore.rooms, (acc, room) => acc + (room.roomRate * room.quantity), 0) / 100) * 30)
	const subtotal = (_.reduce(paramsInStore.rooms, (acc, room) => acc + (room.roomRate * room.quantity), 0) / 100);
	const localTax = (((totalAdults + totalChildren) * diffDays) * 1000)
    const amount = ((subtotal + ((subtotal * 0.1) + (localTax / 100)) + (subtotal * 0.1)) * 30)
    const roomsCLean = _.map(paramsInStore.rooms, (room) => ({
      roomTypeID: room.roomTypeID,
      roomTypeName: room.roomTypeName,
      roomRate: room.roomRate,
      quantity: room.quantity,
    }))

	// console.log("DEPOSIT DUE", amount)

    const roomsData = !_.isEmpty(paramsInStore.rooms)
      ? roomsCLean
      : [{ roomTypeID: '', quantity: 0 }]

    const childrenData = !_.isEmpty(paramsInStore.children)
      ? paramsInStore.children
      : [{ roomTypeID: '', quantity: 0 }]

    const adultsData = !_.isEmpty(paramsInStore.rooms)
      ? paramsInStore.adults.map(x => {
        x.quantity = x.quantity || 1;
        return x;
      })
      : [{ roomTypeID: '', quantity: 1 }]

    const bodyFormData = new FormData()
    console.log('Token Id', token.id)
    bodyFormData.append('guestFirstName', values.firstName)
    bodyFormData.append('guestLastName', values.lastName)
    bodyFormData.append('guestEmail', values.email)
    bodyFormData.append('guestPhone', values.prefix + values.phone)
    bodyFormData.append('guestCountry', values.country)
    bodyFormData.append('startDate', paramsInStore.startDate)
    bodyFormData.append('endDate', paramsInStore.endDate)
    bodyFormData.append('tokenId', token.id)
    bodyFormData.append('promoCode', '')
    bodyFormData.append('cardType', _.toLower(token.card.brand))
    bodyFormData.append('paymentMethod', 'debit')
    bodyFormData.append('amount', amount)
    bodyFormData.append('currency', 'JPY')
    bodyFormData.append('children', JSON.stringify(childrenData))
    bodyFormData.append('adults', JSON.stringify(adultsData))
    bodyFormData.append('rooms', JSON.stringify(roomsData))

    let bookingFormDetails = {};
    for (const pair of bodyFormData.entries()) {
        bookingFormDetails = {
            ...bookingFormDetails,
            [pair[0]]: pair[1]
        }
    }
    sessionStorage.setItem('bookingFormDetails', JSON.stringify(bookingFormDetails))

    makeRequest({
      headers,
      custom: true,
      method: 'POST',
      endPoint: 'postReservation',
      data: bodyFormData,
    }).then((resp) => {
      setPaymentLoading(false)
      if (resp.success) {
        sessionStorage.setItem('bookingDetails', JSON.stringify(resp))
        navigate(`${getLangLink()}checkout-complete/`)
        sessionStorage.removeItem('paramsBooking')
      } else {
        setHasError(true)
		toast.error(resp.message, {
			position: "top-right",
			autoClose: 5000,
		  });
      }
    }).catch((err) => console.log('ERROR', err))
  }

  const onHandledPaymentClick = async (e, values) => {
    try {
      e.preventDefault()
	  setPaymentLoading(true)
      const card = elements.getElement(CardElement)
      const { token } = await stripe.createToken(card)
      onHandledPayment(token, values)
    } catch (err) {
		  setPaymentLoading(false)
      setHasError(true)
      toast.error('There was an error with the card details.', {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }

  const getRoomsWithName = () => {
    const r = JSON.parse(decodeURIComponent(_.get(params, 'rooms')))
    const permittedValues = r.map((value) => value.roomtypeid)
    const roomsWithName = _.filter(roomsAvailables.propertyRooms, (item) => {
      if (_.includes(permittedValues, _.toNumber(item.roomTypeID))) {
        return true
      }

      return false
    })
    setBookingRooms(roomsWithName)
  }

  const formatPeopleInRooms = (roomsPeople) => _.reduce(roomsPeople, (acc, curr) => acc + curr.quantity, 0)
  useEffect(() => {
    if (params) {
      setAdults(formatPeopleInRooms(JSON.parse(decodeURIComponent(_.get(params, 'adults')))))
      setChildren(formatPeopleInRooms(JSON.parse(decodeURIComponent(_.get(params, 'children')))))
    }
  }, [params])

  useEffect(() => {
    const paramsBooking = JSON.parse(sessionStorage.getItem('paramsBooking'))

    if (!paramsBooking) {
      navigate(`${getLangLink()}booking/`)
    }
    setParamsInStore(paramsBooking)
    getHotelDetails()
    return () => {
      sessionStorage.removeItem('paramsBooking')
      // setTotalRooms({})
    }
  }, [])

  useEffect(() => {
    getRoomsWithName()
  }, [roomsAvailables])

  const viewProps = {
    isPaymentLoading,
    loadingProperty,
    hotelDetails,
    enddate: _.get(params, 'enddate'),
    startdate: _.get(params, 'startdate'),
    adults,
    children,
    bookingRooms,
    hasError,
    paramsInStore,
    onHandledPaymentClick,
  }
  return (
    <CheckoutView {...viewProps}/>
  )
}

export default CheckoutController
