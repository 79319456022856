import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import _ from 'lodash'
import moment from 'moment'

import {
  AsideCheckout,
  FormContact
} from './components'
import { InfoBooking } from '../booking/components'
import {
  MapLocation,
  PropertyInfo,
  Steps,
  Spinner,
} from '../../components'
import * as styles from './checkout-styles.module.scss'
import LeftArrow from '../../assets/icons/reflections-group-icon-left-arrow.svg'
import Seo from '../../components/Seo'


const CheckoutView = ({
	isPaymentLoading,
  onHandledPaymentClick,
  enddate,
  startdate,
  adults,
  children,
  hotelDetails,
  bookingRooms,
  hasError,
  paramsInStore,
}) => {
  const { t, i18n } = useTranslation()

  const getLangLink = () => {
    const activeLanguage = i18n?.language.toLowerCase();
    let link = '';
    if(activeLanguage) {
        switch(activeLanguage){
            case 'ja':
                link = '/ja/';
                break;
            case 'zh-hant':
                link = '/zh-hant/';
                break;
            default: link = '/';
        }
    }
    return link;
  }
  const breakpoints = useBreakpoint()
  // if (loadingProperty || isPaymentLoading) {
  //   return <Spinner loading={true} />
  // }
  const [params, setParams] = useState([])
  useEffect(() => {
    const pa = JSON.parse(sessionStorage.getItem('paramsBooking'))
    setParams(pa)
  }, [])

  const [totalRooms, setTotalRooms] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [due, setDue] = useState(0);

  useEffect(() => {
	setTotalRooms(params?.rooms)

	let totalAdults = _.reduce(params.adults, (t, o) => t + o.quantity, 0)
	let totalChildren = _.reduce(params.children, (t, o) => t + o.quantity, 0)
	//   console.log("Total Persons", totalAdults, totalChildren)
	const promoCode = 0
	const totalRoom = _.reduce(params.rooms, (acc, room) => acc + (room.roomRate * room.quantity), 0)
	//   const amount = ((_.reduce(paramsInStore.rooms, (acc, room) => acc + room.roomRate, 0) / 100) * 30)
	const diffDays = moment.duration(moment(enddate).diff(moment(startdate))).asDays()
	const localTax = (((totalAdults + totalChildren) * diffDays) * 1000);
	const amount = (( (totalRoom / 100) + (((totalRoom / 100) * 0.1) + (localTax / 100)) + ((totalRoom / 100) * 0.1) ) * 30)
	// setDue(amount)
	setDue((totalRoom + ((totalRoom * 0.10) + localTax) + (totalRoom * 0.10)) - (promoCode))

  }, [params])

  useEffect(() => {
    const tpr = _.reduce(totalRooms, (result, value) => result + (value.roomRate * value.quantity), 0)
    setTotalPrice(tpr < 0 ? 0 : tpr)
  }, [totalRooms])

  console.log("PARAMS", params)

  return (
    <div className={styles.checkout__container}>
          <Seo title="Kamo - Checkout" />
      <div className={styles.checkout__title}>
        {
          breakpoints.xs && (
            <Link className={styles.checkout__back} to={`${getLangLink()}booking/?startDate=${startdate}&endDate=${enddate}&adults=${adults}&children=${children}`}>
              <p><LeftArrow width="25px"/><span>{t('booking.buttons.back')}</span></p>
            </Link>
          )
        }
        <h1>{t('checkout.title')}</h1>
      </div>

      <div className={styles.checkout__box}>
	  	{ breakpoints.sm && (<>
			<div style={{
				position: 'fixed',
				top: '0',
				right: '20px',
				maxWidth: 'calc(100% - 168px)',
				width: '100%',
				zIndex: 9999
			}}>
				<InfoBooking t={t} suites={_.reduce(totalRooms, (result, value) => result + value.quantity, 0)} nights={_.reduce(totalRooms, (result, value) => result + value.quantity, 0) > 0 ? params.diffDays : 0} diffDays={params.diffDays} edit={false} />
			</div>
		  </>)}
		{params && <aside className={styles.checkout__aside2}>
			<div className={styles.checkout__aside2__prices}>
			<p className={styles.checkout__aside2__room}>{t('booking.aside.suites')} <span>{_.reduce(totalRooms, (result, value) => result + value.quantity, 0)}</span></p>
			<p className={styles.checkout__aside2__room}>{t('booking.aside.nights')} <span>{_.reduce(totalRooms, (result, value) => result + value.quantity, 0) > 0 ? params.diffDays : 0}</span></p>
			<p className={styles.checkout__aside2__price}>{t('booking.aside.total')} <span class="big" dangerouslySetInnerHTML={{ __html: t('booking.aside.price', { price: due }) }}></span></p>
			</div>
			<button disabled className={styles.checkout__aside2__book}><span>{t('booking.buttons.finalize')}</span></button>
		</aside>}
	  	
        <Steps t={t} isSecond/>
		<div>
		<Link className={styles.checkout__back} to={`${getLangLink()}booking/?startDate=${startdate}&endDate=${enddate}&adults=${adults}&children=${children}`}>
			<p><LeftArrow width="25px"/><span>{t('booking.buttons.back')}</span></p>
		</Link>
		</div>
        <div className={styles.checkout__content}>
          <div className={styles.checkout__form}>
            <>
              <FormContact t={t} onHandledPaymentClick={onHandledPaymentClick} isPaymentLoading={isPaymentLoading} />
              <PropertyInfo t={t} data={hotelDetails} contact={false} />
            </>
          </div>
		  <div className={styles.checkout__sidebar}>
			<AsideCheckout
				bookingRooms={bookingRooms}
				enddate={enddate}
				startdate={startdate}
				paramsInStore={paramsInStore}
				t={t}
			/>
			<PropertyInfo t={t} data={hotelDetails} contactOnly={true} checkout={true} />
		  </div>
        </div>

        <div className={styles.checkout__map}>
        <MapLocation isHome={true}/>
    </div>

      </div>

    </div>

  )
}

export default CheckoutView
